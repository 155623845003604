<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="card-profile bg-secondary mt-5">
            <b-card-body class="py-5 px-5">
              <div class="text-center mb-4">
                <h1>Check your email</h1>
                <p class="text-lead">
                  We've sent you a <b>Verify your email</b> message. Please use
                  the link in the email, then click below.
                </p>
              </div>
              <b-form role="form">
                <div class="text-center">
                  <base-button
                    @click="providerLogin"
                    type="primary"
                    class="mt-2"
                    >Check verified status</base-button
                  >
                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  methods: {
    providerLogin() {
      this.$auth.loginWithRedirect({ appState: { targetUrl: '/dashboard' } })
    }
  }
}
</script>
<style></style>
