<template>
  <div>
    <!--Header-->
    <div class="header bg-primary pt-5 pb-7">
      <b-container>
        <div class="header-body">
          <b-row align-v="center mt-6">
            <b-col lg="6">
              <div class="pr-5">
                <h1 class="display-2 text-white font-weight-bold mb-0">
                  Welcome to Zenclear
                </h1>
                <h2 class="display-4 text-white font-weight-light">
                  Simplify your workflow so you can focus on your clients.
                </h2>
                <p class="text-white mt-4">
                  Zenclear is an all-in-one tool for scheduling, payments, and
                  client tracking. Perfect for anyone who does 1:1 mentoring,
                  teaching, and coaching!
                </p>
                <div class="mt-5">
                  <a
                    href="https://qmxzzyqrq2i.typeform.com/to/uqcLocxM"
                    class="btn btn-neutral my-2"
                    >Sign up for Beta waitlist</a
                  >
                  <a
                    href="https://qmxzzyqrq2i.typeform.com/to/kH0Y6rSR"
                    class="btn btn-default my-2"
                    >Ask a Question</a
                  >
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <b-row class="pt-5">
                <b-col md="6">
                  <b-card no-body>
                    <b-card-body>
                      <div
                        class="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4"
                      >
                        <i class="ni ni-calendar-grid-58"></i>
                      </div>
                      <h5 class="h3">Scheduling</h5>
                      <p>
                        Zenclear will integrate with your mobile calendar, send
                        your client automated emails, and text message reminders
                        about upcoming sessions.
                      </p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body>
                    <b-card-body>
                      <div
                        class="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4"
                      >
                        <i class="ni ni-money-coins"></i>
                      </div>
                      <h5 class="h3">Payments</h5>
                      <p>
                        Finally! Your sessions can be tied to your payments to
                        simplify your billing process.
                      </p>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col md="6" class="pt-lg-5 pt-4">
                  <b-card no-body class="mb-4">
                    <b-card-body>
                      <div
                        class="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4"
                      >
                        <i class="ni ni-check-bold"></i>
                      </div>
                      <h5 class="h3">Client Progress</h5>
                      <p>
                        Create to do lists for your client, record assessment
                        scores or grades, and create milestones to track
                        client's progress.
                      </p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body class="mb-4">
                    <b-card-body>
                      <div
                        class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4"
                      >
                        <i class="ni ni-chart-bar-32"></i>
                      </div>
                      <h5 class="h3">Data</h5>
                      <p>
                        You will love how you can easily capture metrics about
                        your business or for a specific client - from number of
                        sessions, missed sessions, total revenue.
                      </p>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <section class="py-6 pb-9 bg-default">
      <b-row class="justify-content-center text-center">
        <b-col md="6">
          <h2 class="display-3 text-white">
            Launching Beta Version of Zenclear!
          </h2>
          <p class="lead text-white">
            Parenting 3 kids, my family has hired a fair share of tutors,
            therapists, life coaches, executive functioning coaches, speech
            therapists, music teachers, swim teachers.... They were all great at
            what they did but they were using subpar tools from paper and pen,
            quickbooks, excel sheets that managing them sometimes impacted the
            quality of their work. Then I started to think. What if they had a
            tool that was simple to use so they could take on more clients and
            do what they truly do best? Welcome to Zenclear. Here to clear your
            mind to focus on the success of your clients.
          </p>
          <p class="display-4 text-white">
            Catherine + Zenclear team
          </p>
          <a
            href="https://qmxzzyqrq2i.typeform.com/to/uqcLocxM"
            class="btn btn-neutral my-2"
            >Sign up for Beta waitlist</a
          >
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
export default {
  name: 'home-page'
}
</script>
