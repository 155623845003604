<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="6" md="9">
          <b-card no-body class="card-profile bg-secondary mt-5">
            <b-card-body class="py-5 px-5">
              <div class="text-center mb-4">
                <h1>Connecting to payment provider</h1>
                <p class="text-lead">
                  Please wait...
                </p>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  mounted() {
    location.href =
      process.env.VUE_APP_API_BASEURL +
      '/payment/clientcard/' +
      this.$route.params.client +
      '?provider=' +
      this.$route.query.provider
  }
}
</script>
<style></style>
