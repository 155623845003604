<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="card-profile bg-secondary mt-5">
            <b-card-body v-if="isCanceled" class="py-5 px-5">
              <div class="text-center mb-4">
                <h1>Session Canceled</h1>
                <p class="text-lead">
                  Thanks! Your session is canceled.
                </p>
              </div>
            </b-card-body>
            <b-card-body v-else class="py-5 px-5">
              <div v-if="!isCanceled" class="text-center mb-4">
                <h1>Cancel Session?</h1>
                <p class="text-lead">
                  Please confirm that you want to cancel this session.
                </p>
              </div>
              <b-form role="form">
                <div class="text-center">
                  <base-button
                    @click="cancelSession"
                    type="primary"
                    class="mt-2"
                    >Confirm Cancel</base-button
                  >
                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import apiService from '@/services/apiService'
import notifications from '@/services/notifications'

export default {
  data() {
    return {
      isCanceled: false
    }
  },
  methods: {
    async cancelSession() {
      try {
        await apiService.editEndpoint('misc', 'cancel-session', {
          provider: this.$route.query.provider,
          session: this.$route.query.session
        })
        this.isCanceled = true
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
<style></style>
