<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Pricing Plans</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="13">
          <b-card-group class="pricing flex-column flex-md-row mb-3">
            <card
              header-classes="bg-transparent"
              body-classes="px-lg-7"
              class="card-pricing border-0 text-center mb-4"
            >
              <h4
                slot="header"
                class="text-uppercase ls-1 text-primary py-3 mb-0"
              >
                Free Basic Plan
              </h4>

              <div class="display-2">$0</div>
              <span class="text-muted">per month</span>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-terminal"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2"> 0 - 5 Clients</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-pen-fancy"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Scheduling & Payments</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Payment Tracking Features</span>
                    </div>
                  </div>
                </li>
              </ul>

              <base-button type="primary" class="mb-3"
                >Signup For Free!*</base-button
              >
              <p>* no credit card required</p>
              <a slot="footer" href="#!" class="text-light">Request a demo</a>
            </card>

            <card
              gradient="info"
              class="
                card-pricing
                zoom-in
                shadow-lg
                rounded
                border-0
                text-center
                mb-4
              "
              header-classes="bg-transparent"
              body-classes="px-lg-7"
              footer-classes="bg-transparent"
            >
              <h4
                slot="header"
                class="text-uppercase ls-1 text-white py-3 mb-0"
              >
                Growth Plan
              </h4>

              <div class="display-1 text-white">$39</div>
              <span class="text-white">per month</span>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-white
                          shadow
                          rounded-circle
                          text-muted
                        "
                      >
                        <i class="fas fa-terminal"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        Advance Planning Tool
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-white
                          shadow
                          rounded-circle
                          text-muted
                        "
                      >
                        <i class="fas fa-pen-fancy"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        Advance Scheduling Features
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-white
                          shadow
                          rounded-circle
                          text-muted
                        "
                      >
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">Payment Automation</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-white
                          shadow
                          rounded-circle
                          text-muted
                        "
                      >
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-white">
                        Automated Communications
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <base-button type="secondary" class="mb-3">
                Start Free Trial
              </base-button>

              <a slot="footer" href="#!" class="text-white">Request A Demo</a>
            </card>
            <card
              header-classes="bg-transparent"
              body-classes="px-lg-7"
              class="card-pricing border-0 text-center mb-4"
            >
              <h4
                slot="header"
                class="text-uppercase ls-1 text-primary py-3 mb-0"
              >
                Premium Plan
              </h4>

              <div class="display-2">$199</div>
              <span class="text-muted">per month</span>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-terminal"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2"
                        >Customizable intake / onboarding forms</span
                      >
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-pen-fancy"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Basic Scheduling Features</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="
                          icon icon-xs icon-shape
                          bg-gradient-primary
                          shadow
                          rounded-circle
                          text-white
                        "
                      >
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Basic Payment Features</span>
                    </div>
                  </div>
                </li>
              </ul>

              <base-button type="primary" class="mb-3"
                >Start Free Trial</base-button
              >

              <a slot="footer" href="#!" class="text-light">Annual Plan</a>
            </card>
          </b-card-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-lg-center px-3 mt-5">
        <div>
          <div
            class="
              icon icon-shape
              bg-gradient-white
              shadow
              rounded-circle
              text-primary
            "
          >
            <i class="ni ni-building text-primary"></i>
          </div>
        </div>
        <b-col lg="6">
          <p class="text-white">
            <strong>Bringing Zen to your business</strong> We want you to easily
            manage, schedule, &amp; invoice, so you can focus on what is
            important - your client!
          </p>
        </b-col>
      </div>
      <b-row class="row-grid justify-content-center">
        <b-col lg="10">
          <div class="table-responsive">
            <base-table class="table table-dark mt-5" :data="features">
              <template slot="columns">
                <th class="px-0 bg-transparent" scope="col">
                  <span class="text-light font-weight-700">Features</span>
                </th>
                <th class="text-center bg-transparent" scope="col">
                  Basic Plan
                </th>
                <th class="text-center bg-transparent" scope="col">
                  Growth Plan
                </th>
                <th class="text-center bg-transparent" scope="col">
                  Premium Plan
                </th>
              </template>

              <template slot-scope="{ row }">
                <td class="px-0">{{ row.title }}</td>
                <td class="text-center" v-html="row.basic"></td>
                <td class="text-center" v-html="row.growth"></td>
                <td class="text-center" v-html="row.premium"></td>
              </template>
            </base-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BaseTable from '@/components/BaseTable'
export default {
  name: 'pricing-page',
  components: {
    BaseTable
  },
  data() {
    return {
      features: [
        {
          title: 'Zoom / Webex / Doxy Integration',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'iCal, Google Calendar Integrations ',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Customizable Client Forms & Contracts',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Dashboard Summary of Activity, Payments, Revenue ',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Number of Clients',
          basic: '<span class="text-sm text-light">Up to 10</span>',
          growth: '<span class="text-sm text-light">Up to 25</span>',
          premium: '<span class="text-sm text-light">Unlimited</span>'
        },
        {
          title: 'Client Database',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Password protected',
          basic: '<i class="fas fa-check text-success"></i>',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'End to End Client Tracking',
          basic: '-',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Automation',
          basic: '-',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Payment Reminders',
          basic: '-',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Audit Trail of Client Communications',
          basic: '-',
          growth: '<i class="fas fa-check text-success"></i>',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'HIPAA Compliance',
          basic: '-',
          growth: '-',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Quickbooks Integrations',
          basic: '-',
          growth: '-',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'Branded Communication Templates',
          basic: '-',
          growth: '-',
          premium: '<i class="fas fa-check text-success"></i>'
        },
        {
          title: 'SMS-enabled Reminders',
          basic: '-',
          growth: '-',
          premium: '<i class="fas fa-check text-success"></i>'
        }
      ]
    }
  }
}
</script>
<style></style>
